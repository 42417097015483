import axios from 'axios';

const token = 'j3kuyqwzre4xfki30rwwd4r69k9fpfj0';
export default axios.create({
    baseURL: 'https://dynamicsoft.pl/rest/default/V1',
    headers: {
      'Authorization': 'Bearer ' + token,
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
        'Allow': 'GET, POST, OPTIONS',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Authorization, Origin, X-Requested-With, Content-Type, Accept',
    },
    crossdomain: true
});
